import React, { FC } from "react";
import Loading from "./Loading";

interface AvatarProps {
  href: string;
  loading?: boolean;
  defaultImage?: string;
  alt?: string;
  className?: string;
}
const Avatar: FC<AvatarProps> = ({
  href,
  loading,
  defaultImage,
  alt,
  className,
}) => {
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <img
          src={`${process.env.REACT_APP_API_HOST}/users/${href}/avatar`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultImage || "/eitrynne_default.png";
          }}
          alt={alt}
          className={`z-0 object-cover ${className}`}
        />
      )}
    </>
  );
};

export default Avatar;
